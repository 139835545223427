import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'
import quillText from '@/components/quillText'

export default {
  formData: [{
    title: i18n.t('table.product.basicInformation'),
    name: 0,
    createDateItem: [{
      span: 8,
      prop: 'push_type',
      name: 'select',
      options: [],
      label: i18n.t('form.notifications.pushType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 8,
      prop: 'scheduled_time',
      name: 'date',
      type: 'datetime',
      label: i18n.t('form.notifications.scheduledTime'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        format: 'yyyy-MM-dd HH:mm',
        'value-format': 'yyyy-MM-dd HH:mm'
      }
    }, {
      span: 8,
      prop: 'notifiable_type',
      name: 'select',
      options: [],
      label: i18n.t('form.notifications.audience'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 8,
      prop: 'title',
      name: 'input',
      label: i18n.t('form.notifications.title'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'short_description',
      name: 'input',
      label: i18n.t('form.notifications.subTitle'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'description',
      name: 'component',
      label: i18n.t('form.notifications.content'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        name: quillText
      }
    }, {
      span: 12,
      prop: 'button_text',
      name: 'input',
      label: i18n.t('form.notifications.buttonText'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 12,
      prop: 'button_link',
      name: 'input',
      label: i18n.t('form.notifications.buttonLink'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }]
  }]
}
